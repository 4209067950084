<template>
    <div class="saleTable additional">
        <el-table
            :data="additionalData"
            stripe
            style="width: 100%"
        >
            <el-table-column
                label="序号"
                type="index"
            >
            </el-table-column>
            <el-table-column
                label="附加型号"
            >
                <template slot-scope="scope">
                    <el-select
                        v-model="scope.row.add_code"
                        @change="selectAdditionalModel(scope.row, scope.$index)"
                        @focus="focusAdditionalModel(scope.row.add_code)"
                    >
                        <el-option
                            v-for="item in additionData"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                            :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="加价">
                <template slot-scope="scope">
                    <input type="text" v-model="scope.row.add_price" @change="preserveAdditional(scope.row, scope.$index)">
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.$index != additionalData.length-1"
                        size="mini"
                        type="danger"
                        class="iconfont delete iconshanchu"
                        @click="deleteAdditional(scope.row, scope.$index)"
                    >
                    </el-button>
                    <el-button
                        size="mini"
                        v-else
                        class="iconfont addTo icontianjia1"
                        @click="addToAdditional(scope.row, scope.$index)"
                    >
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    // 销售价格管理-附加型号 
    name: 'additional',
    components: {},
    props: {
        currentData: {
            type: Object,
        },
    },
    data() {
        return {
            // 切换数据新ID
            newAreaid: '',
            // 确认是否新增
            count: 0,
            deleteId: '',
            // 附加剂配置列表
            additionalData: [],
            // 当前期数id
            price_id: '',
            // 当前区域id
            area_id: 0,
            // 附加型号数据集合
            additionalModelSet: [],
            // 附加型号已处理数据集合
            additionData: [],
            isRegions: false,
        };
    },
    watch: {
        currentData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                this.isRegions = newVal.isRegion;
                if (newVal.isRegion) {
                    this.newAreaid = newVal.areaid;
                } else {
                    this.newAreaid = 0;
                }
                const initData = newVal.initData;
                if (newVal.initData && initData.SalePriceInfo) {
                    if (initData.SalePriceInfo !== 'null' && initData.SalePriceInfo !== null) {
                        this.price_id = initData.SalePriceInfo.id;
                        if (initData.SalePriceInfo.is_area) {
                            if (initData.AreaList !== 'null' && initData.AreaList !== null) {
                                this.area_id = initData.AreaList[0].area_id;
                            }
                        } else {
                            this.area_id = 0;
                        }
                    }
                }
                // 获取附加型号下拉数据
                this.getAdditionalModel();
            },
        },
    },
    created() {
        // 期数id,区域id赋值
        if (this.currentData.initData.SalePriceInfo) {
            if (this.currentData.initData.SalePriceInfo !== 'null' && this.currentData.initData.SalePriceInfo !== null) {
                this.price_id = this.currentData.initData.SalePriceInfo.id;
                if (this.currentData.initData.SalePriceInfo.is_area) {
                    if (this.currentData.initData.AreaList !== 'null' && this.currentData.initData.AreaList !== null) {
                        this.area_id = this.currentData.initData.AreaList[0].area_id;
                    }
                }
            }
        }
        // if (this.currentData) {
        //     // 获取附加型号下拉数据
        //     this.getAdditionalModel();
        // }
    },
    computed: {},
    methods: {
        // 获取附加剂配置列表
        getAdditional() {
            let areaIds;
            if (this.isRegions) {
                areaIds = this.newAreaid ? this.newAreaid : this.area_id;
            } else {
                areaIds = 0;
            }
            if (this.currentData.historyId === '') {return;}
            this.$axios
                .get('/interfaceApi/sale/price_config/get_price_addition/' + this.currentData.historyId + '/' + areaIds)
                .then(res => {
                    if (res.length > 0) {
                        // 附加剂配置数据集合
                        this.additionalData = res;
                        this.filterData(this.additionalModelSet);
                    } else {
                        this.additionalData = [];
                        this.additionData = this.additionalModelSet;
                        this.addToAdditional();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择附加型号获取焦点
        focusAdditionalModel(data) {
            this.additionData.forEach(item => {
                if (item.code === data) {
                    item.disabled = false;
                }
            });
        },
        // 选择附加型号确定
        selectAdditionalModel(data, index) {
            this.additionData.forEach(item => {
                if (item.code === data.add_code) {
                    data.add_name = item.name;
                    item.disabled = true;
                }
            });
            this.preserveAdditional(data, index);
        },
        // 获取附加型号下拉数据
        getAdditionalModel() {
            if (Object.keys(this.additionalModelSet).length > 0) {
                this.getAdditional();
            } else {
                this.$axios
                    .get('/interfaceApi/BaseInfo/dictionary_config/getlist_recursion?parent_code=501&type=5')
                    .then(res => {
                        // 附加型号下拉数据集合赋值
                        res.forEach(item => {
                            if (item.level === 3) {
                                this.additionalModelSet.push(item);
                            }
                        });
                        this.getAdditional();
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 过滤附加型号
        filterData(data) {
            if (data.length > 0) {
                // 未选附加型号赋值
                const ids = [];
                this.additionalData.filter(item => ids.push(item.add_code));
                data.forEach(item => {
                    if (ids.indexOf(item.code) !== -1) {
                        item.disabled = true;
                    } else {
                        item.disabled = false;
                    }
                });
                this.additionData = data;
                this.$forceUpdate();
            }
        },
        // 删除附加型号
        deleteAdditional(data, index) {
            if (data.id) {
                this.$axios
                    .delete('/interfaceApi/sale/price_config/delete_price_addition/' + data.id)
                    .then(res => {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                        this.additionalData.splice(index, 1);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.additionalData.splice(index, 1);
            }
        },
        // 添加附加型号
        addToAdditional() {
            this.count++;
            this.additionalData.push({
                id: '',
                price_id: this.price_id,
                area_id: this.area_id,
                add_code: '',
                add_name: '',
                add_price: 0,
            });
        },
        // 保存附加型号 
        preserveAdditional(data, index) {
            // data.id = this.deleteId;
            data.area_id = this.newAreaid;
            if (data.add_code === '') {
                this.$message({
                    message: '请先选择附加剂型号！！',
                    type: 'warning',
                });
            } else {
                this.$axios
                    .put('/interfaceApi/sale/price_config/modify_price_addition', data)
                    .then(res => {
                        if (res) {
                            // if (this.count > 0) {
                            //     this.deleteId = res;
                            // }
                            this.additionalData[index].id = res;
                            this.$message({
                                message: '修改成功',
                                type: 'success',
                            });
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.additional
    .el-table
        .el-select
            width 1.5rem
</style>